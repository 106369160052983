import React from 'react';

import { Check } from '@mui/icons-material';
import {
	Box,
	Card,
	type CardProps,
	CardActionArea,
	CardContent,
	CardMedia,
	Chip,
	Divider,
	Stack,
	Typography,
	type SxProps,
	type Theme,
} from '@mui/material';

import FeaturedBadge from '@ivy/components/atoms/FeaturedBadge';
import RouteLink, {
	type RouteLinkProps,
} from '@ivy/components/atoms/RouteLink';
import { StarIcon } from '@ivy/components/icons';
import { LocationIcon, BriefcaseIcon } from '@ivy/components/icons';
import { combineSx } from '@ivy/lib/styling/sx';

interface StatObject {
	label: string;
	value: React.ReactNode;
	isLink?: boolean;
	linkProps?: RouteLinkProps;
	chipLabel?: string;
}

export interface MapCardDataObject {
	picture?: string;
	description?: string;
	badgeText?: string;
	badgeRate?: string;
	customBadge?: string;
	title?: string;
	rating?: string;
	locationInfo?: string;
	businessInfo?: string;
	stats?: StatObject[];
	pathname?: string;
	linkProps?: RouteLinkProps;
}

interface CardDetailsProps {
	entity: MapCardDataObject;
	isVerticalOrientation: boolean;
}

const CardDetails = ({ entity, isVerticalOrientation }: CardDetailsProps) => {
	const renderStats = (stats: StatObject[]) => {
		return stats.map((stat: StatObject) => {
			if (!stat.isLink) {
				return (
					<Box key={stat.label} display='flex' overflow='hidden'>
						<Typography
							variant='body2'
							color='text.secondary'
							fontWeight='bold'
							noWrap
							sx={{
								flex: '0 0 50%',
								// Spacing between label and value if they start to collide
								pr: 1,
							}}
						>
							{stat.label}
						</Typography>
						<Typography variant='body2' noWrap>
							{stat.value}
						</Typography>
					</Box>
				);
			} else {
				return (
					<Box key={stat.label} display='flex' overflow='hidden'>
						<Typography
							variant='body2'
							color='text.secondary'
							fontWeight='bold'
							noWrap
							sx={{
								flex: '0 0 50%',
								// Spacing between label and value if they start to collide
								pr: 1,
							}}
						>
							{stat.label}
						</Typography>
						<Typography
							component={RouteLink}
							variant='body2'
							noWrap
							onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
							{...stat.linkProps}
						>
							{stat.value}
						</Typography>
						{stat.chipLabel && (
							<Chip
								size='small'
								variant='outlined'
								label={stat.chipLabel}
								sx={(theme) => ({
									position: 'relative',
									ml: 1,
									flex: '0 0 auto',
									height: 'auto',
									'& .MuiChip-label': {
										...theme.typography.caption,
										px: 1,
										position: 'relative',
									},
								})}
							/>
						)}
					</Box>
				);
			}
		});
	};

	return entity?.stats?.length || entity?.description ? (
		<>
			<Divider
				sx={{
					width: {
						xs: '100px',
						sm: '100px',
					},
					my: 2,
				}}
			/>
			<Box
				sx={{
					...(entity.stats?.length && entity.description
						? {
								display: 'flex',
								flexDirection: {
									xs: 'column',
									sm: isVerticalOrientation ? 'column' : 'row',
								},
						  }
						: {}),
				}}
			>
				{!!entity.stats?.length && (
					<Stack
						spacing={1}
						sx={{
							width: {
								sm:
									!isVerticalOrientation && entity.description
										? 'calc(50% - 8px)'
										: undefined,
							},
							mr: {
								sm: isVerticalOrientation ? undefined : 1,
							},
						}}
					>
						{renderStats(entity.stats)}
					</Stack>
				)}
				{!!entity.description && (
					<Typography
						variant='body2'
						sx={{
							...(entity.stats?.length
								? {
										width: {
											sm: isVerticalOrientation ? undefined : 'calc(50% - 8px)',
										},
										ml: {
											sm: isVerticalOrientation ? undefined : 1,
										},
										mt: {
											xs: 1,
											sm: isVerticalOrientation ? 1 : 0,
										},
								  }
								: {}),
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							display: '-webkit-box',
							WebkitLineClamp: {
								xs: 3,
								sm: isVerticalOrientation ? 3 : 5,
							},
							WebkitBoxOrient: 'vertical',
						}}
					>
						{entity.description}
					</Typography>
				)}
			</Box>
		</>
	) : null;
};

export interface MapCardProps extends CardProps {
	entity: MapCardDataObject;
	selectNode?: React.ReactNode;
	actionAreaSx?: SxProps<Theme>;
	selected?: boolean;
	wagePosition?: 'left' | 'right';
	orientation?: 'dynamic' | 'horizontal' | 'vertical';
	hideRating?: boolean;
	onClick?: () => void;
	openInNewTab?: boolean;
	badgeIcon?: React.ReactNode;
	badgeSxProps?: SxProps<Theme>;
}

const MapCard = ({
	entity,
	selectNode,
	actionAreaSx,
	onClick,
	selected = false,
	wagePosition = 'right',
	orientation = 'dynamic',
	hideRating = false,
	openInNewTab = false,
	badgeIcon,
	badgeSxProps = {},
	...props
}: MapCardProps) => {
	const isVerticalOrientation = orientation === 'vertical';

	return (
		<Card elevation={0} {...props}>
			<CardActionArea
				component='div'
				onClick={onClick}
				sx={combineSx(
					{
						p: 2,
						height: '100%',
						display: 'flex',
						flexDirection: {
							xs: 'column',
							sm: isVerticalOrientation ? 'column' : 'row-reverse',
						},
						// CardMedia is taking height 0 when inside CardActionArea, so stretch it
						alignItems: 'stretch',
						borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					},
					actionAreaSx,
				)}
			>
				{entity.picture ? (
					<CardMedia
						image={entity.picture}
						sx={{
							// This will blur the edge a bit. Solution is to wrap in a box to keep the sharp border, but it
							// messes with the sm breakpoint since the height goes to 0
							// filter: !entity.picture?.publicUrl && 'blur(1px)',
							position: 'relative',
							overflow: 'hidden',
							minHeight: {
								xs: '175px',
								sm: isVerticalOrientation ? '175px' : '0',
							},
							width: {
								xs: '100%',
								sm: isVerticalOrientation ? '100%' : '250px',
							},
							maxWidth: {
								sm: isVerticalOrientation ? 'none' : '40%',
							},
							borderRadius: (theme) => `${theme.shape.borderRadius}px`,
						}}
					>
						<Box
							sx={{
								display: selected ? 'flex' : 'none',
								justifyContent: 'center',
								alignItems: 'center',
								position: 'absolute',
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								bgcolor: 'primary.main',
								opacity: '0.7',
								color: 'primary.contrastText',
							}}
						>
							<Check
								sx={{
									height: '33%',
									width: 'auto',
								}}
							/>
						</Box>
						{(!!entity.badgeRate || !!entity.badgeText) && (
							<FeaturedBadge
								icon={!entity.badgeRate && badgeIcon}
								variant='caption'
								sx={{
									m: 1,
									top: 0,
									zIndex: 1,
									position: 'absolute',
									left: wagePosition === 'left' ? 0 : undefined,
									right: wagePosition === 'right' ? 0 : undefined,
									px: 2,
									...badgeSxProps,
								}}
								label={entity.badgeRate || entity.badgeText}
							/>
						)}
						{!!entity.customBadge && (
							<Box
								sx={[
									{
										m: 1,
										pt: 0.5,
										pb: 0.3,
										px: 1.5,
										backgroundColor: '#fff',
										borderRadius: '999px',
										boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
										display: 'flex',
										alignItems: 'center',
										bottom: 0,
										left: 0,
										zIndex: 1,
										position: 'absolute',
									},
								]}
							>
								<Box
									component='img'
									src={entity.customBadge}
									sx={{
										width: '75px',
										height: 'auto',
									}}
								/>
							</Box>
						)}
					</CardMedia>
				) : undefined}
				<CardContent
					sx={{
						// flex: 1 0 auto doesn't work, also need the shrink
						flex: '1',
						pl: 0,
						pb: 0,
						pt: {
							xs: 1,
							sm: isVerticalOrientation ? 1 : 0,
						},
						pr: {
							xs: 0,
							sm: isVerticalOrientation ? 0 : 1,
						},
						overflow: 'hidden',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Box
						sx={{
							display: 'flex',
						}}
					>
						<Box overflow='hidden'>
							<Box display='flex' alignItems='center'>
								{!!selectNode && (
									<Box
										sx={{
											mr: 0.5,
										}}
										onClick={(ev) => ev.stopPropagation()}
									>
										{selectNode}
									</Box>
								)}
								{entity.linkProps ? (
									<Typography
										variant='body1'
										fontWeight='bold'
										noWrap
										flex='0 1 auto'
										component={RouteLink}
										underline='none'
										color='inherit'
										onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
										{...entity.linkProps}
										openInNewTab={openInNewTab}
									>
										{entity.title}
									</Typography>
								) : (
									<Typography
										variant='body1'
										fontWeight='bold'
										noWrap
										flex='0 1 auto'
									>
										{entity.title}
									</Typography>
								)}
								{entity.rating && !hideRating && (
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											flexFlow: 'row nowrap',
										}}
										color='text.icon'
										pl={1}
									>
										<StarIcon
											sx={{
												mr: 1,
												mt: -0.3,
											}}
										/>{' '}
										<Typography variant='body2' noWrap>
											{entity.rating}
										</Typography>
									</Box>
								)}
								{!entity.picture && !!entity.badgeRate && (
									<Typography
										variant='body1'
										fontWeight='bold'
										whiteSpace='nowrap'
										sx={{
											ml: 'auto',
											pl: 0.5,
											display: {
												sm: !isVerticalOrientation ? 'none' : undefined,
											},
										}}
									>
										{entity.badgeRate}
									</Typography>
								)}
							</Box>
							{(entity.locationInfo || entity.businessInfo) && (
								<Stack
									display='flex'
									mt={1}
									sx={{
										display: 'flex',
										flexDirection: {
											xs: 'column',
											sm: 'row',
										},
									}}
								>
									{entity.locationInfo && (
										<Typography
											variant='body2'
											color='text.icon'
											noWrap
											flex='0 0 auto'
											sx={{
												mr: {
													xs: 0,
													sm: 2,
												},
												mb: {
													xs: 1,
													sm: 0,
												},
											}}
										>
											<LocationIcon
												sx={{
													fontSize: '16px',
													position: 'relative',
													top: '3px',
													mr: '2px',
												}}
											/>
											{entity.locationInfo}
										</Typography>
									)}
									{entity.businessInfo && (
										<Typography
											variant='body2'
											color='text.icon'
											noWrap
											flex='1 1'
										>
											<BriefcaseIcon
												sx={{
													fontSize: '16px',
													position: 'relative',
													top: '3px',
													mr: '4px',
												}}
											/>
											{entity.businessInfo}
										</Typography>
									)}
								</Stack>
							)}
						</Box>
						{!entity.picture && (!!entity.badgeRate || !!entity.badgeText) && (
							<Box
								sx={{
									display: {
										xs: 'none',
										sm: isVerticalOrientation ? 'none' : 'initial',
									},
									ml: 'auto',
									pl: 1,
								}}
							>
								<FeaturedBadge
									icon={!entity.badgeRate && badgeIcon}
									sx={{
										px: 2,
										...badgeSxProps,
									}}
									variant='caption'
									label={entity.badgeRate || entity.badgeText}
								/>
							</Box>
						)}
					</Box>
					<CardDetails
						entity={entity}
						isVerticalOrientation={isVerticalOrientation}
					/>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

export default MapCard;
