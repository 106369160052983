import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';

import Popup, { type PopupProps } from '@ivy/components/molecules/Popup';
import { gql } from '@ivy/gql/types';
import {
	type ApplyToJobPopup_ContractFragment,
	type ApplyToJobPopup_PostingFragment,
} from '@ivy/gql/types/graphql';

const AllAddOnsPopup_ApplyMDoc = gql(/* GraphQL */ `
	mutation AllAddOnsPopup_Apply(
		$contractIds: [String!]!
		$postingIds: [String!]
	) {
		apply_to_jobs(
			input: { contract_ids: $contractIds, posting_ids: $postingIds }
		) {
			success
		}
	}
`);

export interface AllAddOnsPopupProps
	extends Omit<PopupProps, 'actions' | 'disableCloseButton'> {
	contracts: readonly ApplyToJobPopup_ContractFragment[];
	postings?: readonly ApplyToJobPopup_PostingFragment[] | null;
	refetchQueries?: string[];
	onComplete?: () => void;
	multiFac: boolean;
	multiRec: boolean;
}

/**
 * A popup that renders when a clinician has already applied to a set of contracts (recruiter + facility combos).
 * This popup does not require any message, as the job applications will be treated as "add-ons" to the pre-existing
 * applications sent to these contracts.
 */
const AllAddOnsPopup = ({
	onClose,
	contracts,
	postings,
	onComplete,
	multiFac,
	multiRec,
	refetchQueries,
	...props
}: AllAddOnsPopupProps) => {
	const { enqueueSnackbar } = useSnackbar();
	const [applyAddOns, { loading }] = useMutation(AllAddOnsPopup_ApplyMDoc, {
		variables: {
			contractIds: contracts.map((el) => el.id),
			postingIds: postings?.map((el) => el.id) ?? null,
		},
		refetchQueries,
		awaitRefetchQueries: true,
	});

	const handleClickSubmitAllAddOns = useCallback(async () => {
		try {
			await applyAddOns();
			enqueueSnackbar(
				`Great work! Your job ${
					contracts.length > 1 ? 'applications have' : 'application has'
				} been submitted.`,
				{
					variant: 'success',
				},
			);
			onComplete?.();
		} catch (e) {
			console.error(e);
			captureException(e, {
				extra: {
					contracts,
					postings,
				},
			});
			// Possibly did not confirm email or, in the future, too many applications sent
			enqueueSnackbar(
				e instanceof Error ? e.message : 'An error occurred, please try again.',
				{
					variant: 'error',
				},
			);
		}
	}, [applyAddOns, contracts, postings, enqueueSnackbar, onComplete]);

	return (
		<Popup
			disableCloseButton
			onClose={onClose}
			actions={
				<>
					<Button
						onClick={onClose}
						disabled={loading}
						sx={{
							mr: 'auto',
						}}
					>
						Cancel
					</Button>
					<Button
						id='form-connect--btn-submit'
						variant='contained'
						disabled={loading}
						onClick={handleClickSubmitAllAddOns}
					>
						Sounds good!
					</Button>
				</>
			}
			{...props}
		>
			<Typography variant='body1'>
				Since you've previously submitted a job application to{' '}
				{multiRec ? 'these recruiters' : 'this recruiter'} for other facilities,
				we'll let them know on your original job application that you're also
				interested in{' '}
				{multiFac ? (
					'these additional facilities'
				) : (
					<b>{contracts[0].facility.name}</b>
				)}
				.
			</Typography>
		</Popup>
	);
};

export default AllAddOnsPopup;
