import React from 'react';

import RouteLink from '@ivy/components/atoms/RouteLink';
import Map, { type MapWrapperProps } from '@ivy/components/organisms/Map';
import { type BaseMapItemObject } from '@ivy/components/organisms/Map/BaseMap';
import { residencyFilters } from '@ivy/components/organisms/Map/filters/filterData';
import { TrainingType } from '@ivy/constants/clinician';
import { FACILITY_PLACEHOLDER_IMAGE } from '@ivy/constants/facility';
import { getFragmentData, gql } from '@ivy/gql/types';
import {
	type Map_GetResidencyQuery,
	type Map_GeoSearchResidencyQuery,
} from '@ivy/gql/types/graphql';
import { buildInternalLink } from '@ivy/lib/util/route';

// Residency
export const Map_GeoSearchResidencyQDoc = gql(/* GraphQL */ `
	query Map_GeoSearchResidency(
		$xMin: float8!
		$xMax: float8!
		$yMin: float8!
		$yMax: float8!
		$filters: residency_bool_exp!
	) {
		residency: search_residency_by_geography(
			args: { x_min: $xMin, x_max: $xMax, y_min: $yMin, y_max: $yMax }
			where: { residency: $filters }
			order_by: [
				{ residency: { training: { featured: desc } } }
				{ distance: asc }
			]
		) {
			id
			residency {
				id
				training {
					id
					slug
				}
				emrafied
				latestSurvey: latest_survey {
					id
					survey {
						id
						trainingSurvey: training_survey {
							id
							...Map_TrainingSurvey
						}
					}
				}
			}
		}
	}
`);
export const Map_ResidencyQDoc = gql(/* GraphQL */ `
	query Map_GetResidency($id: uuid!) {
		residency: residency_by_pk(id: $id) {
			id
			training {
				id
				slug
			}
			emrafied
			latestSurvey: latest_survey {
				id
				survey {
					id
					trainingSurvey: training_survey {
						id
						...Map_TrainingSurvey
					}
				}
			}
		}
	}
`);
export const Map_TrainingSurveyFDoc = gql(/* GraphQL */ `
	fragment Map_TrainingSurvey on training_survey {
		id
		location
		city
		state
		residencySurvey: residency_survey {
			id
			residency {
				id
				training {
					id
					name
					featured
				}
			}
			programLength: program_length
			primaryTrainingSite: primary_training_site
			secondaryTrainingSite: secondary_training_site
			specialty
		}
		clerkshipSurvey: clerkship_survey {
			id
			clerkship {
				id
				training {
					id
					name
					featured
				}
			}
			residency {
				id
				training {
					id
					name
					slug
				}
			}
			comlexRequired: comlex_required
			rotationTypes: rotation_types
			vsloParticipant: vslo_participant
		}
		fellowshipSurvey: fellowship_survey {
			id
			fellowship {
				id
				training {
					id
					name
					featured
				}
			}
			residency {
				id
				training {
					id
					name
					slug
				}
			}
			advancedDegrees: advanced_degrees
			moonlightingAllowed: moonlighting_allowed
			programLength: program_length
			subspecialty
		}
		trainingSites: training_sites(where: { primary: { _eq: true } }) {
			facility {
				picture {
					publicUrl: public_url
				}
			}
		}
	}
`);

const resolver = (
	rawData: Map_GeoSearchResidencyQuery['residency'][0]['residency'],
): BaseMapItemObject => {
	const res = getFragmentData(
		Map_TrainingSurveyFDoc,
		rawData!.latestSurvey!.survey!.trainingSurvey,
	);

	let newObject: BaseMapItemObject = {
		id: rawData!.id,
		disabled: false,
		location: null,
		picture: FACILITY_PLACEHOLDER_IMAGE,
		stats: [],
	};

	newObject.stats = [
		{
			label: 'Program Length:',
			value: `${res.residencySurvey?.programLength || 'N/A'} year(s)`,
		},
		{
			label: 'Specialties:',
			value: res.residencySurvey?.specialty.join(', '),
		},
		{
			label: 'Primary Training Site:',
			value: res.residencySurvey?.primaryTrainingSite,
		},
		{
			label: 'Secondary Training Site:',
			value: res.residencySurvey?.secondaryTrainingSite,
		},
	];

	const resPathname = buildInternalLink(RouteLink.routes.EMRA_RESIDENCY_SHOW, {
		trainingId: [rawData!.id, rawData!.training.slug],
	});
	const resFeatured = res.residencySurvey?.residency?.training.featured;

	newObject = {
		...newObject,
		location: res.location,
		title: res.residencySurvey?.residency?.training.name,
		locationInfo: `${res.city}, ${res.state}`,
		picture:
			(res.trainingSites.length > 0 &&
				res.trainingSites[0].facility?.picture?.publicUrl) ||
			FACILITY_PLACEHOLDER_IMAGE,
		pathname: resPathname,
		linkProps: {
			to: {
				pathname: resPathname,
			},
			state: {
				backNav: {
					target: 'search',
				},
			},
		},
		markerFeatured: resFeatured,
		resultFeatured: resFeatured,
		customBadge: rawData?.emrafied
			? 'https://storage.googleapis.com/ivy-assets/images/emrafied-simple.png'
			: undefined,
		badgeText: resFeatured ? 'Featured' : undefined,
	};

	return newObject;
};

const handleResolver = (data: Map_GeoSearchResidencyQuery | undefined) =>
	data ? data.residency.map(({ residency }) => resolver(residency)) : undefined;

const handlePreviewResolver = (data: Map_GetResidencyQuery | undefined) =>
	data ? resolver(data.residency) : undefined;

type ResidencyMapProps = Omit<
	MapWrapperProps<Map_GeoSearchResidencyQuery, Map_GetResidencyQuery>,
	| 'entityType'
	| 'queryDoc'
	| 'queryPreviewDoc'
	| 'filters'
	| 'dataResolver'
	| 'dataPreviewResolver'
	| 'dataNearbyResolver'
	| 'resolver'
	| 'previewResolver'
	| 'nearbyResolver'
>;

const ResidencyMap = ({ ...props }: ResidencyMapProps) => {
	return (
		<Map
			entityType={TrainingType.RESIDENCY}
			queryDoc={Map_GeoSearchResidencyQDoc}
			queryPreviewDoc={Map_ResidencyQDoc}
			filters={residencyFilters}
			resolvePageTitle={() => 'Search residencies'}
			resolver={handleResolver}
			previewResolver={handlePreviewResolver}
			{...props}
		/>
	);
};

export default ResidencyMap;
