import React, { useEffect, useRef } from 'react';

import { useQuery } from '@apollo/client';
import { subDays } from 'date-fns';

import DataLoader from '@ivy/components/molecules/DataLoader';
import FormPopup, {
	type MasterFormPopupProps,
} from '@ivy/components/organisms/FormPopup';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql, type FragmentType, getFragmentData } from '@ivy/gql/types';
import {
	type ApplyToJobPopup_ContractFragment,
	type ApplyToJobPopup_PostingFragment,
	type ApplyToJobPopup_ExistingBatchesAndCvQuery,
	FileUpload_FileFragmentDoc,
} from '@ivy/gql/types/graphql';

import AllAddOnsPopup from './AllAddOnsPopup';
import {
	steps,
	type ApplyToJobExtra,
	type ApplyToJobValues,
} from './applyToJobForm';

const ApplyToJobPopup_ExistingBatchesAndCvQDoc = gql(/* GraphQL */ `
	query ApplyToJobPopup_ExistingBatchesAndCv(
		$accountId: uuid!
		$recruiterIds: [uuid!]!
		$cutoff: timestamp!
	) {
		jobAppBatches: job_application_batch(
			where: {
				candidate: { clinician_id: { _eq: $accountId } }
				recruiter_id: { _in: $recruiterIds }
				created_at: { _gte: $cutoff }
			}
		) {
			id
			recruiterId: recruiter_id
		}
		clinician: clinician_by_pk(id: $accountId) {
			id
			cv {
				id
				...FileUpload_File
			}
		}
		prevBatch: job_application_batch(
			where: { candidate: { clinician_id: { _eq: $accountId } } }
			order_by: [{ created_at: desc }]
			limit: 1
		) {
			id
			intro
		}
	}
`);

const ApplyToJobPopup_ContractFDoc = gql(/* GraphQL */ `
	fragment ApplyToJobPopup_Contract on facility_contract {
		id
		facility {
			id
			name
			picture {
				id
				publicUrl: public_url
			}
		}
		org {
			id
			name
		}
		recruiter {
			id
			account {
				id
				pi: personal_info {
					firstName: first_name
					lastName: last_name
					fullName: full_name
				}
			}
		}
	}
`);

const ApplyToJobPopup_PostingFDoc = gql(/* GraphQL */ `
	fragment ApplyToJobPopup_Posting on job_posting {
		id
	}
`);

interface JobPopupProps
	extends Omit<
		MasterFormPopupProps<ApplyToJobValues, ApplyToJobExtra>,
		'steps' | 'defaultValues' | 'extra'
	> {
	contracts: readonly ApplyToJobPopup_ContractFragment[];
	postings?: readonly ApplyToJobPopup_PostingFragment[] | null;
	open: boolean;
	refetchQueries?: string[];
	onComplete: () => void;
	dupRecruiterIds?: string[];
	cv?: NonNullable<
		ApplyToJobPopup_ExistingBatchesAndCvQuery['clinician']
	>['cv'];
	prevIntro?: string;
}

/**
 * Renders either the AllAddOnsPopup in the event a clinician is applying to a recruiter they've recently
 * contacted or the form to submit a new job applications otherwise.
 */
const JobPopup = ({
	open,
	onClose,
	contracts,
	postings,
	onComplete,
	dupRecruiterIds,
	cv,
	refetchQueries,
	prevIntro,
	...props
}: JobPopupProps) => {
	const currAcc = useCurrentAccount();
	if (!currAcc) {
		return null;
	}

	// Check to see if the clinician has already recently applies to every recruiter on the list
	const allAddOns =
		!!dupRecruiterIds &&
		contracts.every((el) => dupRecruiterIds.includes(el.recruiter.id));

	const cvFile = getFragmentData(FileUpload_FileFragmentDoc, cv);
	const multiFac =
		[...new Set(contracts.map((el) => el.facility.id))].length > 1;
	const multiRec =
		[...new Set(contracts.map((el) => el.recruiter.id))].length > 1;

	if (allAddOns) {
		return (
			<AllAddOnsPopup
				contracts={contracts}
				postings={postings}
				open={open}
				onClose={onClose}
				onComplete={onComplete}
				refetchQueries={refetchQueries}
				multiFac={multiFac}
				multiRec={multiRec}
			/>
		);
	}

	return (
		<FormPopup
			open={open}
			onClose={onClose}
			onSuccess={onComplete}
			steps={steps}
			refetchQueries={refetchQueries}
			// If already applied to a job, no need to see the Prompt screen on mobile
			skipPrompt={!!prevIntro}
			extra={{
				clinicianId: currAcc.id,
				cv: cvFile,
				multiFac,
				multiRec,
				contracts,
				postings,
				prevIntro,
			}}
			initialValues={
				cvFile
					? {
							cv: cvFile,
					  }
					: undefined
			}
			{...props}
		/>
	);
};

type JobPopupWithDataFetcherProps = JobPopupProps;

/**
 * Intermediary component that fetches required data for the `JobPopup` component.
 */
const JobPopupWithDataFetcher = ({
	contracts,
	postings,
	...props
}: JobPopupWithDataFetcherProps) => {
	const currAcc = useCurrentAccount();
	// Use a ref so we don't cause an infinite loop with useQuery everytime cutoff cahnges
	const cutoff = useRef(subDays(new Date(), 7));
	const { data, loading, error } = useQuery(
		ApplyToJobPopup_ExistingBatchesAndCvQDoc,
		{
			variables: {
				accountId: currAcc?.id,
				recruiterIds: contracts.map((el) => el.recruiter.id),
				cutoff: cutoff.current,
			},
			// Might have uploaded CV later
			fetchPolicy: 'network-only',
		},
	);

	const dupRecruiterIds = data?.jobAppBatches.map((el) => el.recruiterId);
	const cv = data?.clinician?.cv;
	const prevIntro = data?.prevBatch[0]?.intro;

	return (
		<DataLoader
			data={data}
			loading={loading}
			error={error}
			keys={['clinician']}
			fullscreen
			variant='circular'
			transparent
		>
			{() => (
				<JobPopup
					contracts={contracts}
					postings={postings}
					dupRecruiterIds={dupRecruiterIds}
					cv={cv}
					prevIntro={prevIntro}
					{...props}
				/>
			)}
		</DataLoader>
	);
};

export interface ApplyToJobPopupProps
	extends Omit<JobPopupWithDataFetcherProps, 'contracts' | 'postings'> {
	contracts: readonly FragmentType<typeof ApplyToJobPopup_ContractFDoc>[];
	postings?: FragmentType<typeof ApplyToJobPopup_PostingFDoc>[];
}

const ApplyToJobPopup = ({
	open,
	onClose,
	contracts: rawContracts,
	postings: rawPostings,
	...props
}: ApplyToJobPopupProps) => {
	const contracts = getFragmentData(ApplyToJobPopup_ContractFDoc, rawContracts);
	const postings = getFragmentData(ApplyToJobPopup_PostingFDoc, rawPostings);
	const currAcc = useCurrentAccount();
	const {
		authPopup,
		openProfilePopup,
		openConfirmEmailPopup,
		openSignupPopup,
	} = useAuthPopup();

	useEffect(() => {
		if (!open) {
			return;
		}
		if (!currAcc) {
			// We will skip the CompleteSignupPopup and open the ProfileCompletePopup ourselves in this useEffect, so
			// tell the SignPopup not to attempt it
			openSignupPopup(true, {
				// If user fails to sign up OR login, close this popup
				onFailure: onClose,
			});
		} else if (!currAcc.isClinician) {
			// User logs in as an employer or other user type
			onClose?.();
		} else if (!currAcc.ci?.confirmedEmail) {
			// Confirm email before completing profile
			openConfirmEmailPopup({
				// If user fails to confirm email, close this popup
				onFailure: onClose,
			});
		} else if (!currAcc.clinician!.profileComplete) {
			// Open ProfileCompletePopup if the user just completed signup or the user is already logged in but hasn't
			// completed their profile.
			openProfilePopup({
				// If user fails to complete profile, close this popup
				onFailure: onClose,
			});
		}
	}, [
		open,
		currAcc,
		openProfilePopup,
		openSignupPopup,
		openConfirmEmailPopup,
		onClose,
	]);

	if (
		!contracts.length ||
		!currAcc ||
		!currAcc.isClinician ||
		!currAcc.clinician!.profileComplete ||
		!currAcc.ci?.confirmedEmail ||
		authPopup
	) {
		return null;
	}

	return (
		<JobPopupWithDataFetcher
			open={open}
			onClose={onClose}
			contracts={contracts}
			postings={postings}
			{...props}
		/>
	);
};

export default ApplyToJobPopup;
