import { useState, useEffect } from 'react';

import Hotjar from '@hotjar/browser';

import { useCurrentAccount } from '@ivy/gql/hooks';
import useStringifiedMemo from '@ivy/lib/hooks/useStringifiedMemo';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';
import { type WhitelabelConfigName } from '@ivy/lib/whitelabel/whitelabelConfig';

export interface HotjarOptions {
	disabled?: boolean;
	debug?: boolean;
	whitelabels?: WhitelabelConfigName[];
}

export const HOTJAR_VERSION = 6;

const useHotjar = (siteId?: number, options?: HotjarOptions) => {
	const [init, setInit] = useState(false);
	const currAcc = useCurrentAccount();
	const wl = useWhitelabel();

	const optionWhitelabels = useStringifiedMemo(options?.whitelabels);

	useEffect(() => {
		if (
			init ||
			!siteId ||
			options?.disabled ||
			(optionWhitelabels && !optionWhitelabels.includes(wl.name))
		) {
			return;
		}
		Hotjar.init(siteId, HOTJAR_VERSION, {
			debug: options?.debug,
		});
		setInit(true);
	}, [
		init,
		siteId,
		options?.disabled,
		optionWhitelabels,
		wl.name,
		options?.debug,
		setInit,
	]);

	useEffect(() => {
		if (!init || !currAcc?.id) {
			return;
		}
		// Identify if already logged in or if log in later
		Hotjar.identify(currAcc.id, {
			email: currAcc.ci?.email ?? '',
			full_name: currAcc.pi?.fullName ?? '',
			role: currAcc.type,
			...(currAcc.isOrgUser
				? {
						org_id: currAcc.orgUser?.org.id,
						org_name: currAcc.orgUser?.org.name,
				  }
				: {}),
		});
	}, [
		init,
		currAcc?.id,
		currAcc?.ci?.email,
		currAcc?.pi?.fullName,
		currAcc?.type,
		currAcc?.isOrgUser,
		currAcc?.orgUser?.org.id,
		currAcc?.orgUser?.org.name,
	]);
};

export default useHotjar;
