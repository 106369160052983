import React from 'react';

import RouteLink from '@ivy/components/atoms/RouteLink';
import Map, { type MapWrapperProps } from '@ivy/components/organisms/Map';
import { type BaseMapItemObject } from '@ivy/components/organisms/Map/BaseMap';
import { fellowshipFilters } from '@ivy/components/organisms/Map/filters/filterData';
import { TrainingType } from '@ivy/constants/clinician';
import { FACILITY_PLACEHOLDER_IMAGE } from '@ivy/constants/facility';
import { getFragmentData, gql } from '@ivy/gql/types';
import {
	type Map_GetFellowshipQuery,
	type Map_GeoSearchFellowshipQuery,
} from '@ivy/gql/types/graphql';
import { buildInternalLink } from '@ivy/lib/util/route';

import { Map_TrainingSurveyFDoc } from './ResidencyMap';

const Map_GeoSearchFellowshipQDoc = gql(/* GraphQL */ `
	query Map_GeoSearchFellowship(
		$xMin: float8!
		$xMax: float8!
		$yMin: float8!
		$yMax: float8!
		$filters: fellowship_bool_exp!
	) {
		fellowship: search_fellowship_by_geography(
			args: { x_min: $xMin, x_max: $xMax, y_min: $yMin, y_max: $yMax }
			where: { fellowship: $filters }
			order_by: [
				{ fellowship: { training: { featured: desc } } }
				{ distance: asc }
			]
		) {
			id
			fellowship {
				id
				training {
					id
					slug
				}
				latestSurvey: latest_survey {
					id
					survey {
						id
						trainingSurvey: training_survey {
							id
							...Map_TrainingSurvey
						}
					}
				}
			}
		}
	}
`);
export const Map_FellowshipQDoc = gql(/* GraphQL */ `
	query Map_GetFellowship($id: uuid!) {
		fellowship: fellowship_by_pk(id: $id) {
			id
			training {
				id
				slug
			}
			latestSurvey: latest_survey {
				id
				survey {
					id
					trainingSurvey: training_survey {
						id
						...Map_TrainingSurvey
					}
				}
			}
		}
	}
`);

const resolver = (
	rawData: Map_GeoSearchFellowshipQuery['fellowship'][0]['fellowship'],
): BaseMapItemObject => {
	const fellowship = getFragmentData(
		Map_TrainingSurveyFDoc,
		rawData!.latestSurvey!.survey!.trainingSurvey,
	);

	let newObject: BaseMapItemObject = {
		id: rawData!.id,
		disabled: false,
		location: null,
		picture: FACILITY_PLACEHOLDER_IMAGE,
		stats: [],
	};

	newObject.stats = [
		{
			label: 'Training Opportunity:',
			value: fellowship.fellowshipSurvey?.subspecialty,
		},
		{
			label: 'Advanced Degrees Offered:',
			value: fellowship.fellowshipSurvey?.advancedDegrees.join(', '),
		},
		{
			label: 'Is Moonlighting Allowed:',
			value: fellowship.fellowshipSurvey?.moonlightingAllowed,
		},
		{
			label: 'Program Length:',
			value: fellowship.fellowshipSurvey?.programLength
				? `${fellowship.fellowshipSurvey.programLength} year(s)`
				: undefined,
		},
	];

	if (fellowship.fellowshipSurvey?.residency?.id) {
		newObject.stats.push({
			label: 'Residency:',
			value: fellowship.fellowshipSurvey.residency.training.name,
			isLink: true,
			linkProps: {
				to: {
					pathname: buildInternalLink(RouteLink.routes.EMRA_RESIDENCY_SHOW, {
						trainingId: [
							fellowship.fellowshipSurvey?.residency?.id,
							fellowship.fellowshipSurvey?.residency?.training.slug,
						],
					}),
				},
				state: {
					backNav: {
						target: 'search',
					},
				},
				openInNewTab: true,
			},
		});
	}

	const fellPathname = buildInternalLink(
		RouteLink.routes.EMRA_FELLOWSHIP_SHOW,
		{
			trainingId: [rawData!.id, rawData!.training.slug],
		},
	);
	const fellowFeatured =
		fellowship.fellowshipSurvey?.fellowship?.training.featured;
	newObject = {
		...newObject,
		title: fellowship.fellowshipSurvey?.fellowship?.training.name,
		location: fellowship.location,
		locationInfo: `${fellowship.city}, ${fellowship.state}`,
		picture:
			(fellowship.trainingSites.length > 0 &&
				fellowship.trainingSites[0].facility?.picture?.publicUrl) ||
			FACILITY_PLACEHOLDER_IMAGE,
		pathname: fellPathname,
		linkProps: {
			to: {
				pathname: fellPathname,
			},
			state: {
				backNav: {
					target: 'search',
				},
			},
		},
		markerFeatured: fellowFeatured,
		resultFeatured: fellowFeatured,
		badgeText: fellowFeatured ? 'Featured' : undefined,
	};

	return newObject;
};

const handleResolver = (data: Map_GeoSearchFellowshipQuery | undefined) =>
	data
		? data.fellowship.map(({ fellowship }) => resolver(fellowship))
		: undefined;

const handlePreviewResolver = (data: Map_GetFellowshipQuery | undefined) =>
	data ? resolver(data.fellowship) : undefined;

type FellowshipMapProps = Omit<
	MapWrapperProps<Map_GeoSearchFellowshipQuery, Map_GetFellowshipQuery>,
	| 'entityType'
	| 'fellowship'
	| 'queryDoc'
	| 'queryPreviewDoc'
	| 'filters'
	| 'dataResolver'
	| 'dataNearbyResolver'
	| 'dataPreviewResolver'
	| 'resolver'
	| 'nearbyResolver'
	| 'previewResolver'
>;

const FellowshipMap = ({ ...props }: FellowshipMapProps) => {
	return (
		<Map
			entityType={TrainingType.FELLOWSHIP}
			queryDoc={Map_GeoSearchFellowshipQDoc}
			queryPreviewDoc={Map_FellowshipQDoc}
			filters={fellowshipFilters}
			resolvePageTitle={() => 'Search fellowships'}
			resolver={handleResolver}
			previewResolver={handlePreviewResolver}
			{...props}
		/>
	);
};

export default FellowshipMap;
