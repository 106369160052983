const config = {
	muiLicense: process.env.REACT_APP_MUI_X_LICENSE,
	crispId: process.env.REACT_APP_CRISP_ID,
	// local, development, staging, production (hosted environments)
	env: process.env.REACT_APP_ENV,
	gqlUrl: process.env.REACT_APP_GRAPHQL_URL!,
	apiUrl: process.env.REACT_APP_API_URL!,
	// development, production, test (npm environments)
	nodeEnv: process.env.NODE_ENV,
	gmapsApiKey: process.env.REACT_APP_GMAPS_API_KEY!,
	gtmId: process.env.REACT_APP_GTM_ID,
	sentryDsn: process.env.REACT_APP_SENTRY_DSN,
	ipApiKey: process.env.REACT_APP_IP_API_KEY,
	imageKitUrl: process.env.REACT_APP_IMAGE_KIT_URL!,
	whitelabel: process.env.REACT_APP_WHITELABEL || 'default',
	mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
	mixpanelApiHost: process.env.REACT_APP_MIXPANEL_API_HOST,
	topsortApiKey: process.env.REACT_APP_TOPSORT_API_KEY,
	lightGalleryKey: process.env.REACT_APP_LIGHT_GALLERY_KEY,
	stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
	stripeAccountApiKey: process.env.REACT_APP_STRIPE_ACCOUNT_API_KEY,
	hotjarSiteId: process.env.REACT_APP_HOTJAR_SITE_ID
		? Number.parseInt(process.env.REACT_APP_HOTJAR_SITE_ID)
		: undefined,
};

export default config;
