export const stripHtml = (value: string) => {
	const tempDiv = document.createElement('div');
	tempDiv.innerHTML = value;

	// Use textContent to extract text while preserving line breaks
	const walker = document.createTreeWalker(tempDiv, NodeFilter.SHOW_TEXT, null);

	let text = '';
	while (walker.nextNode()) {
		text += walker.currentNode.nodeValue + ' '; // Add space after each text node
	}

	// Normalize spaces: trim and collapse multiple spaces into one
	return text.replace(/\s+/g, ' ').trim();
};
