import React from 'react';

import { Box, Typography } from '@mui/material';

const FeaturedBadge = ({ icon, sx, label, variant }) => {
	return (
		<Box
			sx={[
				{
					padding: 1,
					backgroundColor: '#ED254E',
					color: '#fff',
					borderRadius: '999px',
					boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
					display: 'flex',
					alignItems: 'center',
					whiteSpace: 'nowrap',
				},
				sx,
			]}
		>
			{icon}
			{label && (
				<Typography
					variant={variant ?? 'body2'}
					fontWeight='bold'
					ml={icon ? 1 : 0}
					color='inherit'
				>
					{label}
				</Typography>
			)}
		</Box>
	);
};

export default FeaturedBadge;
